import React, { useEffect, useState } from "react"
import axios from "axios"
import Pagination from "react-js-pagination"
import { BsChevronLeft, BsChevronRight, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import useLocalStorageState from "../../lib/useLocalStorageState"
import useQuery from "../../lib/useQuery"
import useOAuth from "../../lib/useOAuth"

import Drawing from "../../models/Drawing"

import ConditionCollection from "./ConditionCollection"
import DrawingList from "../drawing-list"
import Loading from "../loading-spinner"
import NoResults from "../search-no-results"
import DisplayOptions from "../drawing-list-options"
import ResultCount from "../result-count"
import ExportButton from "../export-button"

import classes from "./search-advanced.module.scss"

interface SearchAdvancedProps{
    view: string;
    isUserAdmin: boolean;
    AppenedExportList: (items: Drawing[]) => void
    OpenExportList: () => void
}

function isNumber(n:string) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n) } 

export default function SearchAdvanced({ view, AppenedExportList, OpenExportList, isUserAdmin } : SearchAdvancedProps) {
    let { page: pageIdDefault = "1", limit : limitDefault = "25", sorting : sortingDefault = "DWG_NO",  order : orderingDefault = "asc" } = useQuery()

    pageIdDefault = isNumber(pageIdDefault) ? pageIdDefault : "1"    
    limitDefault = isNumber(limitDefault) ? limitDefault : "25"

    const [searchQuery, setSearchQuery] = useLocalStorageState("advancedSearch", { "$and": [ { "DWG_NO": { "$regex": "", "$options": "i" } } ] })
    const [apiData, setApiData ] = useState<any>([])
    const [displayConditions, setDisplayConditions] = useState(true)
    const [performSearch, setPerformSearch] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingNextPage, setLoadingNextPage] = useState(false)
    const [activePage, setActivePage] = useState(parseInt(pageIdDefault))
    const [sorting, setSorting] = useState(sortingDefault)    
    const [ ordering, setOrdering ] = useState(orderingDefault)
    const [resultsPerPage, setResultsPerPage] = useState(limitDefault)
    const [ selectedList, setSelectedList ] = useState<Drawing[]>([])
    const { authKey } = useOAuth()

    let navigate = useNavigate()

    if(view === "search" && !displayConditions)
    {
        setDisplayConditions(true)
    }
    else if(view === "results" && displayConditions)
    {
        setPerformSearch(true)
        setLoading(true)
        setDisplayConditions(false)
    }

    useEffect(() => {
        if(displayConditions) return

        let source = axios.CancelToken.source()

        const postData = {
            page: activePage,
            limit: resultsPerPage,            
            sort: sorting,
            query: searchQuery,
            order: ordering
        }

        axios({
            method: "post",
            url: "/api/search",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            data: postData,
            cancelToken: source.token
        })
            .then(res => {
                setApiData(res.data)
                setLoading(false)
                setPerformSearch(false)
                setLoadingNextPage(false)
            })
        
        navigate(`/search/advanced/results?page=${activePage}&limit=${resultsPerPage}&sorting=${sorting}&order=${ordering}`)
        
        return source.cancel

    }, [displayConditions, performSearch, searchQuery, activePage, loadingNextPage, resultsPerPage, sorting, navigate, authKey, ordering])

    const ChangePageNumber = (newPageNumber:number) => {        
        setLoadingNextPage(true)
        setActivePage(newPageNumber)
        window.scrollTo(0, 0)
    }

    const Paginator = () => {
        return (
            <div className="paginationContainer">                
                { loadingNextPage && <div className="spinner-border text-primary me-2 ms-2" role="status" /> }

                <Pagination
                    activePage={ activePage }
                    itemsCountPerPage={ parseInt(resultsPerPage) }
                    totalItemsCount={ apiData.totalPages * parseInt(resultsPerPage) }
                    pageRangeDisplayed={ 5 }
                    onChange={ ChangePageNumber }
                    firstPageText={ <BsChevronDoubleLeft /> }
                    lastPageText={ <BsChevronDoubleRight /> }
                    prevPageText={ <BsChevronLeft /> }
                    nextPageText={ <BsChevronRight /> }
                    activeLinkClass="active"
                />
            </div>
        )
    }

    const onSearch = () => {
        setPerformSearch(true)
        setLoading(true)
        setDisplayConditions(false)
        navigate("/search/advanced/results")
    }

    const onBackToConditions = () => {
        setDisplayConditions(true)
        navigate("/search/advanced")
    }
    
    const AddDrawingsToExport = () => {        
        AppenedExportList(selectedList)
        setSelectedList([])
    }

    return (
        <div>
            {
                displayConditions && <ConditionCollection query={searchQuery} onChange={(value:any) => setSearchQuery(value)} onSearch={onSearch} /> 
            }

            {
                !displayConditions && loading && <Loading loadingMessage="Performing Advanced Search" />
            }

            {
                !displayConditions && !loading && parseInt(apiData.length) >= 1 && ( 
                    <>
                        <div className={classes.backButtonContainer}>
                            <button className="btn btn-primary" onClick={onBackToConditions}>Back to Search Conditions</button>
                        </div>

                        <div className={ classes.flexApart }>
                            <div>
                                { 
                                    isUserAdmin && <ExportButton AddButton={ selectedList.length > 0 } AddDrawingsClick={ AddDrawingsToExport } ViewDrawingsClick={ OpenExportList } /> 
                                }
                            </div>

                            <div className={classes.flexRight}>
                                <DisplayOptions
                                    resultsPerPageValue={resultsPerPage}
                                    resultsPerPageOnChange={setResultsPerPage}
                                    SortingOptionsValue={sorting}
                                    SortingOptionsOnChange={setSorting}
                                    OrderingOptionsValue={ordering}
                                    OrderingOptionsOnChange={setOrdering}
                                />

                                <Paginator />                            
                            </div>            
                        </div>

                        <DrawingList drawings={apiData.data} selectedList={ selectedList } setSelectedList={ setSelectedList } showListSelection={ isUserAdmin } />

                        <div className={classes.flexRight}>
                            <ResultCount totalResults={apiData.totalLength} />
                            <Paginator />
                        </div>
                    </>
                )
            }

            {
                !displayConditions && !loading && parseInt(apiData.length) === 0 && ( 
                    <>
                        <div className={classes.backButtonContainer}>                            
                            <button className="btn btn-primary" onClick={onBackToConditions}>Back to Search Conditions</button>
                        </div>
                        
                        <NoResults />
                    </>
                )
            }
        </div>
    )
}