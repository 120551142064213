import React from "react"
import { FaTrash } from "react-icons/fa"
import { TreeSectionES, TreeSectionNE, TreeSectionNES } from "./TreeSections"
import { DrawingTypeField } from "../form-controls"
import classes from "./search-advanced.module.scss"

interface SearchFieldProps{
    condition: any;
    onChange: Function;
    onRemove: Function;
    onMoveUp: Function;
    onMoveDown: Function;
    First:Boolean;
    Last:Boolean;
    OnlyItem: Boolean;
}

export default function ConditionField({ condition, onChange, onRemove, onMoveUp, onMoveDown, First, Last, OnlyItem }:SearchFieldProps){
    const allFieldNames = ["DWG_NO","LOCATION","TYPE","TITLEBLOCK","EXTENT","DATE","REMARKS","LASTUSER","ASCON","CONSULTDWGNO","MRC_JOB_NO"]

    const conditionFieldName = Object.keys(condition)[0]
    const conditionComparisonType = typeof(condition[conditionFieldName]) === "object" ? "contains" : "equal"
    const conditionFieldValue = typeof(condition[conditionFieldName]) === "object" ? condition[conditionFieldName].$regex : condition[conditionFieldName]

    const updateFieldName = (event:any) => {
        let returnCondition = { ...condition }

        // Update the Field of the Condition
        // Rename the first key in the object, which changes the field to the new field.
        delete Object.assign(returnCondition, { [event.target.value]: returnCondition[conditionFieldName] })[conditionFieldName]

        onChange(returnCondition)
    }

    const updateComparison = (event:any) => {
        let returnCondition = { ...condition }

        // Update the Comparison of the Condition
        if(event.target.value === "contains")
        {
            // Contains the value
            returnCondition[conditionFieldName] = { "$regex": conditionFieldValue, "$options": "i" } 
        }
        else
        {
            // Equals the value
            returnCondition[conditionFieldName] = conditionFieldValue
        }

        onChange(returnCondition)
    }

    const updateFieldValue = (value:any) => {
        let returnCondition = { ...condition }

        const fieldValue = value.toUpperCase()
            
        // Update the Value of the Condition            
        if(typeof(condition[conditionFieldName]) === "object")
        {
            // Contains the value
            returnCondition[conditionFieldName] = { "$regex": fieldValue, "$options": "i" } 
        }
        else
        {
            // Equals the value
            returnCondition[conditionFieldName] = fieldValue
        }

        onChange(returnCondition)
    }
    return (
        <div className={classes.searchFieldContainer}>

            {
                !OnlyItem && (
                    (First && <TreeSectionES />) ||
                (Last && <TreeSectionNE />) ||
                (!First && !Last && <TreeSectionNES />))
            }
                    
            <div className={classes.searchFieldRow}>
                <div className={classes.marginRight}>                                    
                    <select name="fieldName" id="fieldName" className="form-control" value={conditionFieldName} onChange={updateFieldName}>
                        {
                            allFieldNames.map((value, id) => {
                                return <option value={ value } key={ id }>{ value }</option>
                            })
                        }
                    </select>
                </div>

                <div className={classes.marginRight}>
                    <select name="fieldComparison" id="fieldComparison" className="form-control" value={conditionComparisonType} onChange={updateComparison}>
                        <option value="contains">Contains</option>
                        <option value="equal">Equals</option>
                    </select>
                </div>

                <div className={classes.inputField}>

                    {
                        (
                            conditionFieldName === "TYPE" && (                                
                                <DrawingTypeField value={conditionFieldValue} onChange={ updateFieldValue } />
                            )
                        )
                        ||
                        (
                            <input type="text" name="fieldValue" id="fieldValue" className="form-control" value={conditionFieldValue} onChange={(e) => updateFieldValue(e.target.value)}></input>
                        )
                    }

                </div>

                <div className="btn-group">
                    <button className="btn btn-council-red" onClick={() => onRemove()} title="Remove Item"><FaTrash /></button>
                </div>

            </div>
        </div>
    )
}