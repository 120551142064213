import React, { useEffect, useState } from "react"
import Pagination from "react-js-pagination"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import useQuery from "../../lib/useQuery"
import useOAuth from "../../lib/useOAuth"

import Drawing from "../../models/Drawing"

import SearchBar from "./SearchBar"
import NoResults from "../search-no-results"
import List from "../drawing-list"
import Loading from "../loading-spinner"
import DisplayOptions from "../drawing-list-options"
import ResultCount from "../result-count"
import ExportButton from "../export-button"

import { BsChevronLeft, BsChevronRight, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs"
import DrawingApiRes from "../../models/DrawingApiRes"

import classes from "./search-basic.module.scss"

function isNumber(n:string) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n) } 

interface BasicSearchProps {
    isUserAdmin: boolean;
    AppenedExportList: (items: Drawing[]) => void
    OpenExportList: () => void
}

export default function Basic({ AppenedExportList, OpenExportList, isUserAdmin } : BasicSearchProps) {
    let { q, page: pageIdDefault = "1", limit : limitDefault = "25", sorting : sortingDefault = "DWG_NO",  order : orderingDefault = "asc" } = useQuery()
    
    pageIdDefault = isNumber(pageIdDefault) ? pageIdDefault : "1" 
    limitDefault = isNumber(limitDefault) ? limitDefault : "25"
    
    let navigate = useNavigate()
    const [ apiData, setApiData ] = useState<DrawingApiRes>()
    const [ activePage, setActivePage ] = useState(parseInt(pageIdDefault))
    const [ initialLoading, setInitialLoading] = useState<Boolean>(q !== null)
    const [ loadingNextPage, setLoadingNextPage ] = useState(false)
    const [ searchValue, setSearchValue ] = useState(q || "")
    const [ sorting, setSorting ] = useState(sortingDefault)    
    const [ ordering, setOrdering ] = useState(orderingDefault)
    const [ resultsPerPage, setResultsPerPage ] = useState(limitDefault)
    const [ selectedList, setSelectedList ] = useState<Drawing[]>([])
    const { authKey } = useOAuth()

    useEffect(() => {
        if(!searchValue) {       
            setLoadingNextPage(false)
            setInitialLoading(false)
            return
        }

        let source = axios.CancelToken.source()

        const postData = {
            page: activePage,
            limit: resultsPerPage,
            sort: sorting,
            order: ordering,
            query: {
                $or:
                    [
                        {
                            DWG_NO: { $regex: searchValue, $options: "i" }
                        },
                        {
                            LOCATION: { $regex: searchValue, $options: "i" }
                        },
                        {
                            TITLEBLOCK: { $regex: searchValue, $options: "i" }
                        },
                        {
                            EXTENT: { $regex: searchValue, $options: "i" }
                        }
                    ]
            }
        }

        axios({
            method: "post",
            url: "/api/search",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            data: postData,
            cancelToken: source.token
        })
            .then(res => {
                setApiData(res.data)
                setLoadingNextPage(false)
                setInitialLoading(false)
            })

        navigate(`/search/basic?q=${searchValue}&page=${activePage}&limit=${resultsPerPage}&sorting=${sorting}&order=${ordering}`)
        
        return source.cancel
    }, [searchValue, activePage, sorting, resultsPerPage, navigate, authKey, ordering])

    
    const SearchSubmitted = (newvalue:string) => {
        if(newvalue !== searchValue)
        {
            navigate(`/search/basic?q=${newvalue}`)
            setInitialLoading(true)
            setSearchValue(newvalue)
        }
    }

    const ChangePageNumber = (newPageNumber:number) => {        
        setLoadingNextPage(true)
        setActivePage(newPageNumber)
        window.scrollTo(0, 0)
    }

    const Paginator = () => {
        if(!apiData) return <></>

        return (
            <div className="paginationContainer">                
                { loadingNextPage && <div className="spinner-border text-primary me-2 ms-2" role="status" /> }

                <Pagination
                    activePage={ activePage }
                    itemsCountPerPage={ parseInt(resultsPerPage) }
                    totalItemsCount={ apiData.totalPages * parseInt(resultsPerPage) }
                    pageRangeDisplayed={ 5 }
                    onChange={ ChangePageNumber }
                    firstPageText={ <BsChevronDoubleLeft /> }
                    lastPageText={ <BsChevronDoubleRight /> }
                    prevPageText={ <BsChevronLeft /> }
                    nextPageText={ <BsChevronRight /> }
                    activeLinkClass="active"
                />
            </div>
        )
    }

    const AddDrawingsToExport = () => {        
        AppenedExportList(selectedList)
        setSelectedList([])
    }

    return (
        <>            
            <div className={"container-fluid " + classes.searchBarContainer}>
                <SearchBar searchValue={searchValue} onSubmit={SearchSubmitted} />
            </div>

            <div className="container-fluid">                
                { apiData && apiData.data.length === 0 && !initialLoading && <NoResults />}
                { initialLoading && <Loading />}

                { 
                    apiData && apiData.data.length > 0 && searchValue &&
                        <div>
                            <div className={ classes.flexApart }>
                                <div>
                                    { 
                                        isUserAdmin && <ExportButton AddButton={ selectedList.length > 0 } AddDrawingsClick={ AddDrawingsToExport } ViewDrawingsClick={ OpenExportList } /> 
                                    }
                                </div>

                                <div className={classes.flexRight}>
                                    <DisplayOptions
                                        resultsPerPageValue={resultsPerPage}
                                        resultsPerPageOnChange={setResultsPerPage}
                                        SortingOptionsValue={sorting}
                                        SortingOptionsOnChange={setSorting}
                                        OrderingOptionsValue={ordering}
                                        OrderingOptionsOnChange={setOrdering}
                                    />
                                    <Paginator />
                                </div>
                            </div>

                            <List drawings={apiData.data} selectedList={ selectedList } setSelectedList={ setSelectedList } showListSelection={ isUserAdmin } />
                            
                            <div className={classes.flexRight}>
                                <ResultCount totalResults={apiData.totalLength} />
                                <Paginator />
                            </div>
                        </div>                
                }
            </div>
        </>
    )
}