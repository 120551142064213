import React from "react"
import classes from "./page-not-found.module.scss"

export default function PageNotFound() {
    return (
        
        <div className={ classes.parent }>
            
            <div className={ classes.greyText }>

                <h3>Sorry, this page isn&apos;t available.</h3>

            </div>

            <div className={ classes.greyText }>

                <span>The link you followed may be broken, or the page may have been removed.</span>

            </div>

        </div>
    
    )
}