import React from "react"
import { Drawer } from "rsuite"
import { useNavigate } from "react-router-dom"
import { FaEraser, FaFileExport, FaTimes } from "react-icons/fa"
import "rsuite/dist/rsuite.min.css"
import Drawing from "../../models/Drawing"
import useOAuth from "../../lib/useOAuth"
import axios from "axios"
import classes from "./export-drawer.module.scss"

interface ExportDrawerProps {
    open: boolean;
    close: () => void;
    exportList: Drawing[];
    setListToExport: React.Dispatch<React.SetStateAction<Drawing[]>>;
}

export default function ExportDrawer({ open, close, exportList, setListToExport } : ExportDrawerProps) {
    const { authKey } = useOAuth()    
    let navigate = useNavigate()

    const clearExport = () => {
        setListToExport([])
        close()
    }

    const removeItem = (drawing: Drawing) => {
        let tempExportList = [ ...exportList ]
        let itemIndex = tempExportList.indexOf(drawing)
        tempExportList.splice(itemIndex, 1)
        setListToExport(tempExportList)
    }

    const submitExport = () => {
        let listOfIds = exportList.map(drawing => drawing._id)

        const postData = {
            drawing_numbers: listOfIds
        }

        axios({
            method: "post",
            url: "/api/export",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            data: postData
        })
            .then(() => {
                clearExport()
                navigate("/all-exports")
                window.alert("Submitted Export")
            })
    }

    return (
        <Drawer size={"xs"} placement={"right"} open={open} onClose={close}>
            <Drawer.Header>
                <Drawer.Title>Export List</Drawer.Title>
                <Drawer.Actions>
                    <button className="btn btn-council-green me-3" onClick={ submitExport }>
                        <FaFileExport />
                    </button>
                    
                    <button className="btn btn-council-yellow" onClick={ clearExport }>
                        <FaEraser />
                    </button>                    
                </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body className={classes.drawerBodyMargin}>
                <table className="w-100">
                    <tbody>
                        {
                            exportList.map(drawing => {
                                return (
                                    <tr key={drawing._id} >
                                        <td className="pt-2">
                                            <span>{drawing.DWG_NO}</span>
                                        </td>
                                        <td className={classes["float-right"] + " pt-2"}>
                                            <button className="btn btn-council-yellow" onClick={() => removeItem(drawing)}>
                                                <FaTimes />
                                            </button>
                                        </td>
                                    </tr>)
                            })
                        }
                    </tbody>
                </table>
            </Drawer.Body>
        </Drawer>
    )
}