import React from "react"
import ConditionField from "./ConditionField"
import { FaPlus, FaTrash } from "react-icons/fa"
import { TreeSectionES, TreeSectionNE, TreeSectionNES } from "./TreeSections"

import classes from "./search-advanced.module.scss"

interface ConditionGroupProps {
    query: any;
    onGroupChange: Function;
    onGroupRemove?: Function;
    DisplayTree: Boolean;
    First:Boolean;
    Last:Boolean;
    OnlyItem: Boolean;
}

export default function ConditionGroup({ query, onGroupChange, onGroupRemove, DisplayTree, First, Last, OnlyItem }: ConditionGroupProps) {
    const allConditions = query.$and || query.$or    
    const groupOperator = Object.keys(query)[0]
    const groupOperatorText = query.$and ? "AND" : "OR"
    const groupOperatorBtnCss = (groupOperatorText === "AND" ? classes.btnAnd : classes.btnOr)

    // Update a condition or Group
    const onUpdateChild = (index:number, newCondition:any) => {
        let newQuery = { ...query }
        newQuery[groupOperator][index] = newCondition
        onGroupChange(newQuery)
    }

    // Add a new Condition
    const onAddCondition = () => {
        let newQuery = { ...query }
        newQuery[groupOperator].push({ "DWG_NO": { "$regex": "", "$options": "i" } })
        onGroupChange(newQuery)
    }

    // Add a new Group as a Child
    const onAddGroup = () => {
        let newQuery = { ...query }
        newQuery[groupOperator].push({ "$and": [ { "DWG_NO": { "$regex": "", "$options": "i" } } ] })
        onGroupChange(newQuery)
    }

    // Remove a condition or condition group
    const onRemoveChild = (index:number) => {
        let newQuery = { ...query }
        newQuery[groupOperator].splice(index, 1)
        onGroupChange(newQuery)
    }

    // Toggle the Query Operator between AND and OR
    const onOperatorChange = () => {        
        let newQuery:any = {}        

        if(query.$and)
        {
            newQuery.$or = query.$and
        }
        else
        {
            newQuery.$and = query.$or
        }

        onGroupChange(newQuery)
    }

    // Move Child Condition up or down
    const OnMoveChild = (currentIndex:number, newIndex:number) => {
        // Check Array Bounds
        if(newIndex >= query[groupOperator].lenght) return
        if(newIndex < 0) return

        // Move Child Item up or down in the Array
        let newQuery = { ...query }
        var element = newQuery[groupOperator][currentIndex]
        newQuery[groupOperator].splice(currentIndex, 1)
        newQuery[groupOperator].splice(newIndex, 0, element)
        onGroupChange(newQuery)
    }

    return (
        <div className={classes.conditionGroupContainer}>
            {
                DisplayTree &&
                !OnlyItem && (
                    (First && <TreeSectionES />) ||
                (Last && <TreeSectionNE />) ||
                (!First && !Last && <TreeSectionNES />))
            }

            <div className={classes.conditionGroup}>
                <div className="">
                    <button className={"me-1 btn " + groupOperatorBtnCss} onClick={onOperatorChange} title="Switch Condition">
                        { groupOperatorText }
                    </button>
                    
                    { 
                        onGroupRemove && (
                            <button className="me-1 btn btn-council-red" onClick={() => onGroupRemove()} title="Remove Group">
                                <FaTrash />
                            </button>                                
                        )
                    }

                    <div className="btn-group">
                        <button className="btn btn-council-green" onClick={() => onAddCondition()} title="Add Rule">
                            <FaPlus /> Add Rule
                        </button>

                        <button className="btn btn-council-green" onClick={() => onAddGroup()} title="Add Group">
                            <FaPlus /> Add Group
                        </button>
                    </div>                    
                </div>

                <div>
                    { 
                        allConditions.map((condition:any, index:number) => {
                            const isFirst = index === 0
                            const isLast = (allConditions.length - 1) === index
                            const onlyItem = allConditions.length <= 1

                            if(condition.$and || condition.$or)
                            {
                                return <ConditionGroup 
                                    key={index}
                                    query={condition}
                                    onGroupChange={(newGroupCondition:any) => onUpdateChild(index, newGroupCondition)}
                                    onGroupRemove={() => onRemoveChild(index)}
                                    DisplayTree={true}
                                    First={isFirst}
                                    Last={isLast}
                                    OnlyItem={onlyItem}
                                />
                            }
                            else
                            {
                                return <ConditionField 
                                    key={index}
                                    condition={condition}
                                    onChange={(newCondition:any) => onUpdateChild(index, newCondition)}
                                    onRemove={() => onRemoveChild(index)}
                                    onMoveUp={() => OnMoveChild(index, index - 1)}
                                    onMoveDown={() => OnMoveChild(index, index + 1)}
                                    First={isFirst}
                                    Last={isLast}
                                    OnlyItem={onlyItem}
                                />
                            }
                        })
                    }
                </div>                
            </div>
        </div>
    )
}

