import React from "react"
import classes from "./search-advanced.module.scss"

export function TreeSectionES() {
    return (
        <div className={classes.treeESContainer}>
            <div className={classes.treeES}/>
        </div>
    )
}

export function TreeSectionNE() {
    return (
        <div className={classes.treeNEContainer}>
            <div className={classes.treeNE}/>
        </div>
    )
}

export function TreeSectionNES() {
    return (
        <div className={classes.treeNESContainer}>
            <div className={classes.treeNES}/>
        </div>
    )
}