import React from "react"
import NoResultsImage from "./NoSearchResult.png"
import classes from "./search-no-results.module.scss"

export default function SearchNoResults() {
    return (
        <div className={classes.noSearchResultsContainer}>
            <img src={NoResultsImage} alt="No Results" />

            <h5>Sorry, but there are no results.</h5>            
        </div>
    )
}