import React, { useState } from "react"
import { useParams } from "react-router"

import ViewDrawing from "./drawing-view"
import EditDrawing from "./drawing-edit"
import ListAttachments from "./attachments-view"

import classes from "./drawing-view-single.module.scss"

interface DrawingViewSingleProps {
    isUserAdmin: boolean
}

export default function DrawingViewSingle({ isUserAdmin } : DrawingViewSingleProps) {
    const { drawingId } = useParams()
    const [ editMode, setEditMode ] = useState(false)
    
    if (drawingId === undefined) {
        return <div>No drawing ID</div>
    }

    return (
        <div className={"row " + classes.rowDrawingEditor}>
            <div className={"col-xl-6 " + classes.verticalLine}>
                {
                    (isUserAdmin && editMode) ? <EditDrawing drawing_id={ drawingId } ExitEdit={() => setEditMode(false) } /> 
                        : <ViewDrawing drawing_id={ drawingId } EnterEdit={() => setEditMode(true) } isUserAdmin={ isUserAdmin } /> 
                }
            </div>
            
            <div className="col-xl-6">
                
                <ListAttachments drawing_id={ drawingId } isUserAdmin={ isUserAdmin } />

            </div>
        </div>
    )
}