import React from "react"
import axios from "axios"
import { useState } from "react"
import NewBulkDrawing from "../../models/NewBulkDrawing"
import { useNavigate } from "react-router-dom"
import { TextField, DrawingSizeField, DateField, CheckboxField, NumberField, DrawingTypeField } from "../form-controls"
import { FaPlusCircle } from "react-icons/fa"
import useOAuth from "../../lib/useOAuth"

export default function DrawingNewMulti() {
    const [ newDrawing, setNewDrawing ] = useState<NewBulkDrawing>(new NewBulkDrawing())
    const [ submitting, setSubmitting ] = useState(false)
    const { authKey } = useOAuth()
    let navigate = useNavigate()

    const SubmitNewForm = () => {
        setSubmitting(true)
        
        axios({
            method: "post",
            url: "/api/drawings/bulk",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            data: newDrawing
        })
            .then(() => {
                navigate("/drawings/")
            })
            .catch(err => {
                console.log(err)
                window.alert("Could not add the new drawing at this time. Please try again later!")
                setSubmitting(false)
            })
    }

    const containsAValue = (value:string) => {
        if(value)
        {
            return true
        }
        else
        {
            return false
        }
    }

    const drawingValidation = (drawing: NewBulkDrawing) => {
        let valid = true
    
        if (!drawing.LOCATION) valid = false
        if (!drawing.TYPE) valid = false
        if (!drawing.TITLEBLOCK) valid = false
        if (!drawing.EXTENT) valid = false
        if (!drawing.DATE) valid = false
    
        return valid
    }

    return (
        <div className="container-fluid w-50 mt-3">
            
            <div className="d-flex">
                <div className="w-50 me-1">
                    <NumberField label="Number of Drawings:" value={newDrawing.DRAWINGCOUNT} onChange={(value:string) => parseInt(value) > 1 && parseInt(value) < 200  && setNewDrawing({ ...newDrawing, DRAWINGCOUNT: value}) }/>
                </div>

                <div className="w-50 ms-1">
                    <DrawingSizeField label="Drawing Size:" value={newDrawing.DRAWINGSIZE} onChange={(value:string) => setNewDrawing({ ...newDrawing, DRAWINGSIZE: value}) } />
                </div>            
            </div>

            <div className="card p-3 mt-3">

                <div className="d-flex justify-content-center">                    
                    <h5>Template</h5>
                </div>
                
                <TextField label="Location:" value={newDrawing.LOCATION} onChange={(value:string) => setNewDrawing({ ...newDrawing, LOCATION: value}) } isValid={ containsAValue(newDrawing.LOCATION) } />

                <DrawingTypeField label="Type:" value={newDrawing.TYPE} onChange={(value:string) => setNewDrawing({ ...newDrawing, TYPE: value}) } isValid={ containsAValue(newDrawing.TYPE) } />

                <TextField label="Title Block:" value={newDrawing.TITLEBLOCK} onChange={(value:string) => setNewDrawing({ ...newDrawing, TITLEBLOCK: value}) } isValid={ containsAValue(newDrawing.TITLEBLOCK) } />

                <TextField label="Extent:" value={newDrawing.EXTENT} onChange={(value:string) => setNewDrawing({ ...newDrawing, EXTENT: value}) } isValid={ containsAValue(newDrawing.EXTENT) } />

                <DateField label="Date:" value={newDrawing.DATE} onChange={(value:string) => setNewDrawing({ ...newDrawing, DATE: value }) } isValid={ containsAValue(newDrawing.DATE) } />

                <TextField label="Remarks:" value={newDrawing.REMARKS} onChange={(value:string) => setNewDrawing({ ...newDrawing, REMARKS: value}) } />

                <CheckboxField label="As Constructed:" value={newDrawing.ASCON} onChange={(value:any) => setNewDrawing({ ...newDrawing, ASCON: value}) } />

                <TextField label="Consultant DWG No:" value={newDrawing.CONSULTDWGNO} onChange={(value:string) => setNewDrawing({ ...newDrawing, CONSULTDWGNO: value}) } />

                <TextField label="MRC Job Number:" value={newDrawing.MRC_JOB_NO}  onChange={(value:string) => setNewDrawing({ ...newDrawing, MRC_JOB_NO: value}) }/>
            
            </div>

            <div className="d-flex justify-content-center">
                <button onClick={SubmitNewForm} className="mt-3 mb-4 btn btn-council-green" disabled={submitting || !drawingValidation(newDrawing)} title="Create Drawing">
                    { submitting && ( 
                        <>
                            <div className="spinner-border spinner-border-sm text-white me-2" role="status" />
                            Submitting
                        </>
                    )}

                    { !submitting && ( 
                        <>
                            <FaPlusCircle className="me-1" />
                            { `Add ${newDrawing.DRAWINGCOUNT} Drawings` }
                        </>
                    )}
                </button>
            </div>

        </div>
    )
}
