import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

import "bootstrap/dist/css/bootstrap.min.css"
import "./theme.global.scss"

import { PublicClientApplication } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import authConfig from "./lib/authConfig"

const msalInstance = new PublicClientApplication(authConfig())

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={{ scopes: authConfig().scopes }} >
                <App />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById("root")
)