
export default function configuration() {
    const clientId = process.env.REACT_APP_AAD_CLIENT_ID || ""
    return {
        auth: {
            clientId, // This is the ONLY mandatory field that you need to supply.
            authority: "https://login.microsoftonline.com/MRCQLD.onmicrosoft.com", // Defaults to "https://login.microsoftonline.com/common"
            redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level: any, message: any, containsPii: any) => {
                    console.info(message)
                }
            }
        },
        scopes: [ process.env.REACT_APP_AAD_SCOPE || "User.Read" ]
    }
}