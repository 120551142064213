import { useState, useEffect } from "react"
import { useMsal } from "@azure/msal-react"
import AuthConfig from "./authConfig"

export default function useOAuth() {
    const [ authKey, setAuthKey ] = useState("")
    const { instance, accounts } = useMsal()
    const authConfig = AuthConfig()

    useEffect(() => {
        const accessTokenRequest = {
            scopes: authConfig.scopes,
            account: accounts[0]
        }

        instance.acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {            
                setAuthKey(accessTokenResponse.accessToken)
            }).catch((error) => {
                console.log(error)
            })

    }, [instance, accounts, authConfig.scopes])

    return { account: accounts[0], authKey }
}