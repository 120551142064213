import { useLocation } from "react-router"



export default function useQuery(){
    let allQueryParams: {
        [key: string]: string
       } = { }

    const searchParams = new URLSearchParams(useLocation().search)

    searchParams.forEach((value:string, key:string) => {
        allQueryParams[key] = value
    })

    return allQueryParams
}