export default class NewBulkDrawing {
    public DRAWINGSIZE: string = "A0"
    public DRAWINGCOUNT: string = "1"
    public LOCATION: string = ""
    public TYPE: string = ""
    public TITLEBLOCK: string = ""
    public EXTENT: string = ""
    public DATE: string = ""
    public COPY: string = ""
    public REMARKS: string = ""
    public ASCON: string = "false"
    public CONSULTDWGNO: string = ""
    public MRC_JOB_NO: string = ""
}

/*
{
    "_id": "615b8add4d2d783965ee3024",
    "DRAWINGSIZE": "A1",
    "LOCATION": "EVANS AVE",
    "TYPE": "WATER",
    "TITLEBLOCK": "WATER MAIN RENEWAL, SAFETY IN DESIGN RISK REGISTER - SHEET 4 OF 4",
    "EXTENT": "CANBERRA STREET TO HARBOUR RD",
    "DATE": "14/09/2021",
    "COPY": "",
    "REMARKS": "",
    "IMAGE": 0,
    "LASTUSER": "williammc",
    "MODDATE": "19:25.0",
    "ASCON": 0,
    "CONSULTDWGNO": "",
    "MRC_JOB_NO": 63406
}
*/