import React from "react"

interface FieldProps {
    label: string;
    value: any;
    onChange: Function;
    isValid?: Boolean
}

export function TextField({ label, value, onChange, isValid }: FieldProps) {
    let formInputCss = ""

    if (isValid === true) formInputCss = "is-valid"
    if (isValid === false) formInputCss = "is-invalid"

    const handleChange = (e: any) => {
        onChange(e.target.value.toUpperCase())
    }

    return (
        <div className="form-group mt-2 mb-2">

            <label className="text-muted form-label mb-1">{label}</label>

            <input type="text" className={"form-control " + formInputCss} value={value} onChange={handleChange} />

        </div>
    )
}

export function CheckboxField({ label, value, onChange }: FieldProps) {
    return (
        <div className="form-group mt-2 mb-2">

            <label className="text-muted form-label me-2  mb-1">{label}</label>

            <input type="checkbox" className="form-check-input" onChange={(e) => onChange(String(e.target.checked))} checked={value === "true"} />

        </div>
    )
}

export function DateField({ label, value, onChange, isValid }: FieldProps) {
    let formInputCss = ""
    
    if (isValid === true) formInputCss = "is-valid"
    if (isValid === false) formInputCss = "is-invalid"

    return (
        <div className="form-group mt-2 mb-2">

            <label className="text-muted form-label mb-1">{label}</label>

            <input type="date" className={"form-control " + formInputCss} value={value} onChange={(e) => onChange(e.target.value)} />

        </div>
    )
}

export function DrawingSizeField({ label, value, onChange }: FieldProps) {
    return (
        <div className="form-group mt-2 mb-2">

            <label className="text-muted form-label mb-1">{label}</label>

            <select className="form-control" value={value} onChange={(e) => onChange(e.target.value)}>

                {
                    ["A0", "A1", "A2", "A3", "A4", "RPT"].map((value: string, index: number) => {
                        return <option value={value} key={index}>{value}</option>
                    })
                }

            </select>

        </div>
    )
}

interface DrawingFieldTypeProps {
    label?: string;
    value: any;
    onChange: Function;
    isValid?: Boolean
}

export function DrawingTypeField({ label, value, onChange, isValid }: DrawingFieldTypeProps) {
    const drawingTypes = [
        "LAND",
        "ADMINISTRATION BUILDIN",
        "AIRPORT",
        "AMENITIES BLOCK",
        "BEACH",
        "BIKEWAY",
        "BOAT RAMP",
        "BRIDGE",
        "BUDGET",
        "BUILDING",
        "BUS TERMINAL",
        "CARPARK",
        "CATTLE SALEYARDS",
        "CEMETERY",
        "CHAMBERS",
        "CHART",
        "CHILD CARE CENTRE",
        "CLARIFIER",
        "COMMUNITY",
        "CONCESSION BUILDING",
        "CONTOURS",
        "COVER",
        "CREEK",
        "DAM",
        "DEPOT",
        "TELECOMMUNICATIONS",
        "DEVELOPMENT",
        "DREDGING",
        "DUMP",
        "EARTHWORKS",
        "EASEMENT",
        "ELECTRICAL",
        "ENTERTAINMENT CENTRE",
        "ENVIRONMENT",
        "EROSION",
        "FENCE",
        "FIRE",
        "FLOOD",
        "FLOODWAY",
        "FOOTPATH",
        "GAS",
        "HOUSE CONNECTION",
        "HOUSE NUMBERS",
        "JETTY",
        "LAND",
        "LANDSCAPE",
        "JUBILEE PARK",
        "LEASE",
        "LEVEE",
        "LEVELS",
        "LIBRARY",
        "LIGHTING",
        "LINEMARKING",
        "LOCALITY",
        "MALL",
        "MAP",
        "MARINA",
        "MOSQUITO",
        "MOTEL",
        "NOTES",
        "PARK",
        "PARKING",
        "POPULATION",
        "QUARRY",
        "RADIO",
        "RAILWAY",
        "RAINFALL",
        "RECLAMATION",
        "REPORT",
        "RESERVOIR",
        "RESORT",
        "RESUMPTION",
        "RETAINING WALL",
        "RIVER",
        "ROADWORKS",
        "SCHOOL",
        "SENIOR CITIZENS",
        "SERVICES",
        "SEWERAGE",
        "SIGNS",
        "STANDARD",
        "STANDING WATER LEVELS",
        "STORMWATER",
        "STRATEGY",
        "STRUCTURAL",
        "STUDY",
        "SUBDIVISION",
        "SUBSIDY",
        "SURVEY",
        "SWIMMING POOL",
        "SWITCHBOARD",
        "TAVERN",
        "SAFETY",
        "TIDES",
        "TOURIST",
        "TOWN HALL",
        "TOWN PLAN",
        "TRAFFIC",
        "TRAFFIC SIGNALS",
        "TRAMWAY",
        "P&ID",
        "WATER",
        "WEIR",
        "WORM FARM",
        "ARTSPACE",
        "IRRIGATION",
        "EVACUATION",
        "SEWERAGE PUMP STATION",
        "ESTIMATE",
        "BUS STOP",
        "WATER TREATMENT",
        "SHARED PATHWAY",
        "PIPING",
        "MECHANICAL",
        "FISHWAY",
        "HYDRAULIC",
        "SPARE"
    ]
    let renderedValueOption = false
    let formInputCss = ""

    if (isValid === true) formInputCss = "is-valid"
    if (isValid === false) formInputCss = "is-invalid"

    return (
        <div className="form-group mt-2 mb-2">

            {
                label && <label className="text-muted form-label mb-1">{label}</label>
            }           

            <select className={"form-control " + formInputCss} value={value} onChange={(e) => onChange(e.target.value)}>

                {
                    drawingTypes.map((mapValue: string, index: number) => {

                        if (mapValue === value) renderedValueOption = true

                        return <option value={mapValue} key={index}>{mapValue}</option>
                    })
                }

                {!renderedValueOption && <option value={value}>{value}</option>}

            </select>

        </div>
    )
}

export function NumberField({ label, value, onChange }: FieldProps) {
    return (
        <div className="form-group mt-2 mb-2">

            <label className="text-muted form-label mb-1">{label}</label>

            <input id="temp" type="number" className="form-control" value={value} onChange={(e) => onChange(e.target.value)} />

        </div>
    )
}