import React, { useRef, useState } from "react"
import axios from "axios"
import { FaCloudUploadAlt, FaFolderOpen } from "react-icons/fa"
import useOAuth from "../../lib/useOAuth"
import classes from "./drawing-view-single.module.scss"

interface CreateProps {
    drawing_id: string;
    onCreate: Function;
}

export default function AttachmentNew({ drawing_id, onCreate }: CreateProps) {
    const [attachment, setAttachment] = useState<any>({ revision: "Initial" })
    const [error, setError] = useState("")
    const [uploadingAttachment, setUploadingAttachment] = useState(false)
    const [addingAttachment, setAddingAttachment] = useState(false)

    const { authKey } = useOAuth()

    const fileInput = useRef<HTMLInputElement>(null)
    const maxFileSize = 1024 * 1024 * 15

    function handleFileInput() {
        setAddingAttachment(true)

        if (fileInput.current && fileInput.current.files) {
            var fr = new FileReader()

            fr.onload = () => {
                if (fileInput.current && fileInput.current.files) {
                    if (fileInput.current.files[0].size < maxFileSize) {
                        
                        let name = fileInput.current.files[0].name
                        let ext = name.split(".").pop()

                        if(ext && !["tif", "pdf"].includes(ext)) 
                        {
                            setError("Sorry but the file '" + name + "' is not a supported file type. Please upload a .tif or .pdf file.")
                        }
                        else
                        {
                            setAttachment({ ...attachment, name: fileInput.current.files[0].name, contents: (fr.result as string).split(",").pop() })

                            if (error) setError("")
                        }                        
                    }
                    else {
                        setError("Sorry but the file '" + fileInput.current.files[0].name + "' is too big. Please limit file size to 15MB.")
                    }                    

                    fileInput.current.value = ""
                }

                setAddingAttachment(false)
            }

            fr.readAsDataURL(fileInput.current.files[0])
        }
    }

    function addAttachment() {

        setUploadingAttachment(true)

        axios({
            method: "post",
            url: "/api/attachments",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            data: {
                drawing_id,
                revision: attachment.revision,
                extension: attachment.name.split(".").pop(),
                file: attachment.contents
            }
        })
            .then(result => {
                let { error } = result.data

                if(!error) onCreate()
                else setError("Sorry but the file '" + attachment.name + "' failed an AV Scan, so it cannot be uploaded at this time.")

                setAttachment({})
                setUploadingAttachment(false)
            })
            .catch(console.log)
    }

    function updateRevision(e: any) {
        setAttachment({ ...attachment, revision: e.target.value })
    }

    return (
        <div className="mt-3 mb-4 card">

            <div className="d-flex align-items-center">

                <label className="m-0 p-3">Revision:</label>

                <select name="revision" id="revision" className="form-control" value={attachment.revision ? attachment.revision : ""} onChange={updateRevision} style={{ width: "150px"}}>
                    <option value="Initial">Initial</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                    <option value="M">M</option>
                    <option value="N">N</option>
                    <option value="O">O</option>
                    <option value="P">P</option>
                    <option value="Q">Q</option>
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="T">T</option>
                    <option value="U">U</option>
                    <option value="V">V</option>
                    <option value="W">W</option>
                    <option value="X">X</option>
                    <option value="Y">Y</option>
                    <option value="Z">Z</option>
                </select>       

            </div>

            <div className="d-flex align-items-center justify-content-between">

                <p className="m-3"><strong>Filename:</strong> {attachment.name}</p>

                <div className="btn-group">
                    <button className="btn btn-dark-blue" onClick={ () => fileInput.current && fileInput.current.click() }  disabled={addingAttachment} title="Select File">
                        {
                            addingAttachment ?
                                <div className="spinner-border spinner-border-sm text-white" role="status" />
                                :
                                <span><FaFolderOpen/></span>
                        }
                    </button>

                    <button className="btn btn-council-green me-2" onClick={ addAttachment } disabled={(!attachment.contents || uploadingAttachment)} title="Upload File">                        
                        { 
                            uploadingAttachment ? 
                                <div className="spinner-border spinner-border-sm text-white" role="status" />
                                :
                                <span><FaCloudUploadAlt/></span>
                        }                        
                    </button>                    
                </div>
            </div>

            <input
                ref={fileInput}
                multiple={false}
                accept=".tif, .pdf"
                type="file"
                onChange={handleFileInput}
                style={{ display: "None" }} />

            {error && <p className={classes.attachmentError}>{error}</p>}
        </div>
    )
}