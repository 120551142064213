import React from "react"
import MoonLoader from "react-spinners/MoonLoader"
import classes from "./loading.module.scss"

interface LoadingProps {
    loadingMessage?: string;
}

export default function Loading( { loadingMessage = "Loading..." } : LoadingProps ) {
    return ( 
        <div className={classes.loadingBox}>
            <MoonLoader color="#0032b1" size="40px" />
            <p className={classes.loadingLabel}>{ loadingMessage }</p>
        </div> 
    )
}