import React, { useEffect, useState } from "react"
import axios from "axios"
import { FaTrash, FaCloudDownloadAlt } from "react-icons/fa"

import Attachment from "../../models/Attachment"
import Loading from "../loading-spinner"

import classes from "./drawing-view-single.module.scss"
import Create from "./attachment-new"
import useOAuth from "../../lib/useOAuth"

interface ListProps{
    drawing_id: string;
    isUserAdmin: boolean;
}

export default function AttachmentsView({ drawing_id, isUserAdmin }: ListProps) {
    const [ attachments, setAttachments ] = useState<Attachment[]>([])
    const [ loading, setLoading ] = useState(true)
    const { authKey } = useOAuth()
    
    const ReloadAttachments = () => {
        if(!authKey) return

        axios({
            method: "get",
            url: `/api/attachments/?drawing_id=${drawing_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
        })
            .then(res => {                
                let newAttachments = res.data as Attachment[]
                setAttachments(newAttachments)
                setLoading(false)
            })
    }

    useEffect(ReloadAttachments, [drawing_id, authKey])

    const RemoveAttachment = (attachment_id:string) => {

        if(window.confirm("Are you sure that you want to delete this Attachment? This cannot be undone!"))
        {
            setLoading(true)
            axios({
                method: "delete",
                url: `/api/attachments/${ attachment_id }`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authKey
                },
            })
                .catch(console.log)
                .finally(() => {
                    ReloadAttachments()
                })       
        } 
    }

    const HandleOnCreate = () => {
        setLoading(true)
        ReloadAttachments()
    }

    if(loading) return <Loading loadingMessage="Loading Attachments" />

    const sortedAttachments = attachments.sort((a:Attachment, b:Attachment) => {
        if(a.revision === "Initial" && b.revision === "Initial") { return 0 }
        if(a.revision === "Initial") { return -1 }
        if(b.revision === "Initial") { return 1 }

        if(a.revision < b.revision) { return -1 }
        if(a.revision > b.revision) { return 1 }
        return 0
    })

    return (
        <>            
            { isUserAdmin && <Create drawing_id={ drawing_id } onCreate={HandleOnCreate} /> }

            {
                !isUserAdmin && <div className="mt-4"></div>
            }

            { sortedAttachments.map((attachment, index) => {
                return <SingleAttachmentCard key={index} attachment={attachment} authKey={authKey} RemoveAttachment={RemoveAttachment} showDeleteButton={ isUserAdmin }  />
            })}

            { sortedAttachments.length === 0 && <p className={classes.noAttachmentsMessage}>No attachments available.</p> }
        </>
    )
}

interface SingleAttachmentCardProps{
    attachment: Attachment;
    authKey: string;
    RemoveAttachment: Function;
    showDeleteButton: boolean;
}

function SingleAttachmentCard({ attachment, authKey, RemoveAttachment, showDeleteButton } : SingleAttachmentCardProps){

    const [ isOpening, setIsOpening ] = useState(false)

    const OpenFile = (attachment_id:string) => {
        setIsOpening(true)

        axios({
            method: "get",
            url: `/api/attachments/download-token/${ attachment_id }`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
        })
            .then(attachment_data => {            
                const { token } = attachment_data.data

                const link = document.createElement("a")
                document.body.appendChild(link)
                link.href = `/attachment/${token}`
                link.target = "_blank"
                link.setAttribute("type", "hidden")
                link.click()
                setIsOpening(false)
            })
            .catch(console.log)
    }

    return (
        <div className="card mt-2" key={attachment._id}>

            <div className="d-flex align-items-center justify-content-between">

                <label className="m-0 p-3"><strong>Revision: </strong> { attachment.revision }</label>

                <div className="btn-group me-3">
                                                    
                    <button className="btn btn-dark-blue" onClick={ () => OpenFile(attachment._id) } disabled={isOpening} title="Download File">
                        {
                            isOpening ? 
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 
                                : <FaCloudDownloadAlt />
                        }
                    </button>

                    {
                        showDeleteButton && 
                        <button className="btn btn-council-red" onClick={ () => RemoveAttachment(attachment._id) } title="Delete File">
                            <FaTrash />
                        </button>
                    }

                </div>

            </div>

        </div>
    )
}