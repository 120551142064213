import React, { useEffect, useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { format } from "date-fns"
import { FaTrash, FaEdit } from "react-icons/fa"

import Loading from "../loading-spinner"
import Drawing from "../../models/Drawing"
import useOAuth from "../../lib/useOAuth"
import classes from "./drawing-view-single.module.scss"

interface ViewProps {
    drawing_id: string;
    EnterEdit: Function;
    isUserAdmin: boolean;
}

export default function DrawingView({ drawing_id, EnterEdit, isUserAdmin }: ViewProps) {
    const [drawing, setDrawing] = useState<Drawing>()
    const [loading, setLoading] = useState<Boolean>(true)
    const { authKey } = useOAuth()
    
    let navigate = useNavigate()

    useEffect(() => {
        if (!drawing_id) return
        if (!authKey) return

        axios({
            method: "get",
            url: `/api/drawings/${drawing_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
        })
            .then(res => {                
                setDrawing(res.data)
                setLoading(false)
            })
            .catch()
        
    }, [drawing_id, authKey])
    
    if (loading) return <Loading loadingMessage={ "Loading Drawing!" } />
    if (!drawing) return <h3>No Drawing Found with that ID</h3>

    const onDelete = async () => {
        if(window.confirm("This will delete the Drawing and all related files. This cannot be undone!"))
        {
            axios({
                method: "delete",
                url: `/api/attachments/all/${drawing_id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authKey
                },
            })
                .then(() => {                
                    axios({
                        method: "delete",
                        url: `/api/drawings/${drawing_id}`,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + authKey
                        }
                    })                
                })
                .then(() => {                
                    navigate("/drawings")
                })
                .catch(() => {                
                    window.alert("Sorry but an error occured while deleting this item!")
                    navigate("/drawings")
                })            
        }
    }

    return (
        <div className={"container-fluid " + classes.viewValueContainer}>

            {
                isUserAdmin &&
                <>
                    <div className="btn-group">
                        <button onClick={() => EnterEdit()} className="mt-3 mb-4 btn btn-dark-blue" title="Edit Drawing">
                            <FaEdit />                    
                        </button>
                        
                        <button className="mt-3 mb-4 btn btn-council-red" onClick={onDelete} title="Delete Drawing">
                            <FaTrash />
                        </button>
                    </div>
                </>
            }

            {
                !isUserAdmin && <div className="mt-4"></div>
            }

            <Field label="DWG No:" value={drawing.DWG_NO} />

            <Field label="Location:" value={drawing.LOCATION} />

            <Field label="Type:" value={drawing.TYPE} />

            <Field label="Title Block:" value={drawing.TITLEBLOCK} />

            <Field label="Extent:" value={drawing.EXTENT} />

            <Field label="Date:" value={drawing.DATE ? format(Date.parse(drawing.DATE), "dd/MM/yyyy") : ""} />

            <Field label="Remarks:" value={drawing.REMARKS} />

            <Field label="As Constructed:" value={drawing.ASCON} />

            <Field label="Consultant DWG No:" value={drawing.CONSULTDWGNO} />

            <Field label="MRC Job Number:" value={drawing.MRC_JOB_NO} />

            <Field label="Last Modified By:" value={drawing.LASTUSER} />

            <Field label="Last Modified:" value={ format(Date.parse(drawing.MODDATE), "dd/MM/yyyy hh:mma") } />            

        </div>
    )
}

function Field({ label, value }: any) {
    return (
        <div className={"row " + classes.viewValueRow}>
            <div className="col-xl-4">
                <p className="text-muted">{label}</p>
            </div>
            <div className="col-xl-8">
                <p className={classes.viewValueLabel}>{value}</p>
            </div>
        </div>
    )
}
