import React, { useEffect, useState } from "react"
import axios from "axios"
import useOAuth from "../../lib/useOAuth"

import { FaSave, FaArrowLeft } from "react-icons/fa"
import Loading from "../loading-spinner"
import { TextField, DateField, CheckboxField, DrawingTypeField } from "../form-controls"
import Drawing from "../../models/Drawing"

interface EditProps {
    drawing_id: string;
    ExitEdit: Function;
}

export default function DrawingEdit({ drawing_id, ExitEdit }: EditProps) { 
    const [drawing, setDrawing] = useState<Drawing>()
    const [loading, setLoading] = useState(true)
    const [duplicateDwgNo, setDuplicateDwgNo] = useState(false)
    const { authKey } = useOAuth()
    
    const SubmitEditForm = async () => {
        axios({
            method: "put",
            url: `/api/drawings/${drawing_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            data: drawing
        })
            .then(res => {                
                ExitEdit()
            })
            .catch(err => {
                console.log(err)
                window.alert("Could not update the drawing at this time!")
                ExitEdit()
            })
    }

    const updateDrawingNumber = (value:string) => {

        if(!drawing) return

        setDrawing({
            ...drawing,
            DWG_NO: value
        })

        const dwgCheck = {
            DWG_NO: value,
            Ignore_ID: drawing._id
        }

        axios({
            method: "post",
            url: "/api/dwgno/duplicate",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            data: dwgCheck
        })
            .then(res => {
                const { isDuplicate } = res.data

                if(isDuplicate) {
                    setDuplicateDwgNo(true)
                    window.alert(`The DWG Number '${value}' is already used by another drawing!'`)
                }
                else
                {
                    setDuplicateDwgNo(false)
                }
            })
            .catch(err => {
                console.log(err)            
            })
    }
    
    const containsAValue = (value:string) => {
        if(value)
        {
            return true
        }
        else
        {
            return false
        }
    }
    
    const drawingValidation = (drawing: Drawing) => {
        let valid = true
    
        if (!drawing.DWG_NO) valid = false
        if (!drawing.LOCATION) valid = false
        if (!drawing.TYPE) valid = false
        if (!drawing.TITLEBLOCK) valid = false
        if (!drawing.EXTENT) valid = false
        if (!drawing.DATE) valid = false
    
        return valid
    }

    useEffect(() => {
        if (!drawing_id) return
        if (!authKey) return

        axios({
            method: "get",
            url: `/api/drawings/${drawing_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
        })
            .then(res => {                
                setDrawing(res.data)
                setLoading(false)
            })
            .catch()
        
    }, [drawing_id, authKey])

    if (loading) return <Loading loadingMessage={ "Loading Drawing!" } />
    if (!drawing) return <h3>No Drawing Found with that ID</h3>

    return (
        <div className="container-fluid">
            
            <button onClick={ () => ExitEdit() } className="mt-3 mb-4 me-2 btn btn-council-yellow" title="Cancel"><FaArrowLeft /></button>
            <button onClick={ SubmitEditForm } className="mt-3 mb-4 btn btn-council-green" disabled={ !drawingValidation(drawing) } title="Save"><FaSave /></button>

            <TextField label="DWG No:" value={drawing.DWG_NO} onChange={ updateDrawingNumber } isValid={!duplicateDwgNo || containsAValue(drawing.DWG_NO)} />

            <TextField label="Location:" value={drawing.LOCATION} onChange={(value:string) => setDrawing({ ...drawing, LOCATION: value}) } isValid={ containsAValue(drawing.LOCATION) } />

            <DrawingTypeField label="Type:" value={drawing.TYPE} onChange={(value:string) => setDrawing({ ...drawing, TYPE: value}) } isValid={ containsAValue(drawing.TYPE) } />

            <TextField label="Title Block:" value={drawing.TITLEBLOCK} onChange={(value:string) => setDrawing({ ...drawing, TITLEBLOCK: value}) } isValid={ containsAValue(drawing.TITLEBLOCK) } />

            <TextField label="Extent:" value={drawing.EXTENT} onChange={(value:string) => setDrawing({ ...drawing, EXTENT: value}) } isValid={ containsAValue(drawing.EXTENT) } />

            <DateField label="Date:" value={drawing.DATE} onChange={(value:string) => setDrawing({ ...drawing, DATE: value }) } isValid={ containsAValue(drawing.DATE) } />
            
            <TextField label="Remarks:" value={drawing.REMARKS} onChange={(value:string) => setDrawing({ ...drawing, REMARKS: value}) } />

            <CheckboxField label="As Constructed:" value={drawing.ASCON} onChange={(value:any) => setDrawing({ ...drawing, ASCON: value}) } />

            <TextField label="Consultant DWG No:" value={drawing.CONSULTDWGNO} onChange={(value:string) => setDrawing({ ...drawing, CONSULTDWGNO: value}) } />

            <TextField label="MRC Job Number:" value={drawing.MRC_JOB_NO}  onChange={(value:string) => setDrawing({ ...drawing, MRC_JOB_NO: value}) }/>

        </div>
    )
}