import React from "react"
import classes from "./drawing-list-options.module.scss"

interface DisplayOptionsProps{
    resultsPerPageValue: string;
    resultsPerPageOnChange: Function;
    SortingOptionsValue: string;
    SortingOptionsOnChange: Function;
    OrderingOptionsValue: string;
    OrderingOptionsOnChange: Function;
}

export default function DrawingListOptions({resultsPerPageValue, resultsPerPageOnChange, SortingOptionsValue, SortingOptionsOnChange, OrderingOptionsValue, OrderingOptionsOnChange}: DisplayOptionsProps) {
    return (
        <div className={classes.displayOptionsContainer}>
            <ResultsPerPageOptions value={resultsPerPageValue} onChange={resultsPerPageOnChange}  />
            <SortOptions value={SortingOptionsValue} onChange={SortingOptionsOnChange}  />
            <OrderOptions value={OrderingOptionsValue} onChange={OrderingOptionsOnChange}  />
        </div>
    )
}

interface OrderOptionsProps {
    value: string;
    onChange: Function;
}

const OrderOptions = ({value, onChange}: OrderOptionsProps) => {
    return (
        <div className={classes.optionsContainer}>
            <label>Order By:</label>
            <select className="form-control" value={value} onChange={(e) => onChange(e.target.value)}>
                <option value="asc">ASC</option>
                <option value="desc">DESC</option>           
            </select>
        </div>
    )
}

interface SortOptionsProps {
    value: string;
    onChange: Function;
}

const SortOptions = ({value, onChange}: SortOptionsProps) => {
    return (
        <div className={classes.optionsContainer}>
            <label>Sort By:</label>
            <select className="form-control" value={value} onChange={(e) => onChange(e.target.value)}>
                <option value="DWG_NO">DWG #</option>
                <option value="LOCATION">Location</option>
                <option value="TYPE">Type</option>
                <option value="TITLEBLOCK">Titleblock</option>
                <option value="REMARKS">Remarks</option>                
                <option value="MODDATE">Modifed Date</option>   
            </select>
        </div>
    )
}

interface ResultsPerPageProps {
    value: string;
    onChange: Function;
}

const ResultsPerPageOptions = ({value, onChange}: ResultsPerPageProps) => {
    return (
        <div className={classes.optionsContainer}>
            <label>Per Page:</label>
            <select className="form-control" value={value} onChange={(e) => onChange(e.target.value)}>
                {
                    ["25", "50", "75", "100"].map((value:string, index:number) => {
                        return <option value={value} key={index}>{value}</option>
                    })
                }
            </select>
        </div>
    )
}

