import React from "react"
import { format } from "date-fns"
import { Link } from "react-router-dom"
import { MdOpenInNew } from "react-icons/md"

import Drawing from "../../models/Drawing"
import classes from "./drawing-list.module.scss"

interface ListProps {
    drawings: Drawing[];
    selectedList?: Drawing[];
    setSelectedList?: any;
    showListSelection?: boolean;
}

export default function List({ drawings, selectedList, setSelectedList, showListSelection }: ListProps){
    const OnToggleSelect = (drawing: Drawing) => {          
        if(!selectedList) return
        if(!setSelectedList) return

        if(selectedList.includes(drawing))
        {
            let tempSelectedList = [ ...selectedList ]
            let itemIndex = tempSelectedList.indexOf(drawing)
            tempSelectedList.splice(itemIndex, 1)
            setSelectedList(tempSelectedList)
        }
        else
        {
            let tempSelectedList = [ ...selectedList ]            
            tempSelectedList.push(drawing)
            setSelectedList(tempSelectedList)
        }
    }
    
    return (
        <div className="container-fluid">
            <table className={"table table-hover " + classes.table}>
                <thead className="thead-dark">
                    <tr>
                        { showListSelection && <th scope="col"></th> }
                        <th scope="col"></th>
                        <th scope="col">DWG NO</th>
                        <th scope="col">Location</th>
                        <th scope="col">Titleblock</th>
                        <th scope="col">Extent</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Type</th>
                        <th scope="col">Last Modified</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        drawings.map(drawing => {
                            return <ListItem drawing={ drawing } key={ drawing._id } OnItemSelect={ OnToggleSelect } CurrentlySelected={ selectedList && selectedList.includes(drawing) } showListSelection={showListSelection} />
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

interface ListItemProps {
    drawing: Drawing;
    OnItemSelect: (drawing: Drawing) => void;
    CurrentlySelected?: boolean;
    showListSelection?: boolean;
}

function ListItem({ drawing, OnItemSelect, CurrentlySelected, showListSelection } : ListItemProps){
    if(CurrentlySelected === undefined) CurrentlySelected = false

    return (
        <tr className="align-middle">
            { showListSelection && <td><input type="checkbox" checked={CurrentlySelected} onChange={() => OnItemSelect(drawing)} /></td> }
            <td><Link to={ `/drawings/${drawing._id}/` } className={"btn " + classes.viewButton} title="Open Drawing"><MdOpenInNew /></Link></td>
            <th scope="row">{ drawing.DWG_NO }</th>
            <td>{ drawing.LOCATION }</td>
            <td>{ drawing.TITLEBLOCK }</td>
            <td>{ drawing.EXTENT }</td>
            <td>{ drawing.REMARKS }</td>
            <td>{ drawing.TYPE }</td>
            <td>{ drawing.MODDATE ? format(Date.parse(drawing.MODDATE), "dd/MM/yyyy hh:mma") : "" }</td>
        </tr>
    )
}