import React from "react"
import classes from "./resultCount.module.scss"

interface ResultCountProps {
  totalResults: Number;
}

export default function ResultCount({ totalResults }: ResultCountProps) {
    return (
        <>
            <p className={classes.resultText}>
                <>{totalResults}</> results found
            </p>
        </>
    )
}