import React, { useState } from "react"
import classes from "./search-basic.module.scss"
import { FaSearchPlus } from "react-icons/fa"
import { BsSearch } from "react-icons/bs"

interface SearchBarProps {
    searchValue: string;
    onSubmit: Function;
}

export default function SearchBar({ searchValue, onSubmit }: SearchBarProps) {
    const [ searchInput, setSearchInput ] = useState(searchValue)

    const handleKeyPress = (event:any) => {
        if(event.key === "Enter") onSubmit(searchInput)
    }

    const handleChange = (e:any) => {
        setSearchInput(e.target.value.toUpperCase())
    }

    return (
        <div className={classes.searchBarContainer}>
            <div className={classes.searchBarRow}>               
                <div className={classes.searchInput}>
                    <div className={classes.searchIcon}>                        
                        <BsSearch />
                    </div>
                    
                    <input type="search" 
                        value={searchInput} 
                        className={"form-control " + classes.searchInput}
                        placeholder="eg. DWG No / Location / Titleblock / EXTENT"
                        onChange={ handleChange }
                        onKeyPress={ handleKeyPress }
                    />                
                </div>

                <button className={"btn btn-council-green " + classes.searchButton} 
                    onClick={() => onSubmit(searchInput)} 
                    title="Search">
                    <FaSearchPlus /> Search
                </button>                
            </div>
        </div>   
    )
}