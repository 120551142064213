import React from "react"
// import styles from "./export-list-button.module.scss"
import { FaLayerGroup } from "react-icons/fa"

interface ExportButtonProps {
    AddButton: boolean;
    AddDrawingsClick: () => void;
    ViewDrawingsClick: () => void;
}

export default function ExportButton({ AddButton, AddDrawingsClick, ViewDrawingsClick } : ExportButtonProps){

    const btnText = AddButton ? "Add to Export" : "View Export"
    const btnClass = AddButton ? "btn btn-council-green" : "btn btn-dark-blue"

    const handleClick = () => {
        if(AddButton)
        {
            AddDrawingsClick()
        }
        else
        {
            ViewDrawingsClick()
        }
    }

    return (
        <>
            <button className={ btnClass } onClick={ handleClick }>
                <FaLayerGroup />
                <span className="ps-2">{ btnText }</span>
            </button>        
        </>
    )
}