import { useState } from "react"

export default function useLocalStorageState(key:string, initialValue:any) {

    let localState = localStorage.getItem(key)

    if(localState) {
        localState = JSON.parse(localState)
    }
    else {    
        localState = initialValue
        localStorage.setItem(key, JSON.stringify(localState))
    }

    const [ state, setState ] = useState<any>(localState)

    function updateState(value:any) {
        localStorage.setItem(key, JSON.stringify(value))
        setState(value)
    }
    
    return [ state, updateState ]
}