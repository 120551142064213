import React, { useState } from "react"
import axios from "axios"
import NewSingleDrawing from "../../models/NewSingleDrawing"
import { useNavigate } from "react-router-dom"
import { TextField, DrawingSizeField, DateField, CheckboxField, DrawingTypeField } from "../form-controls"
import { FaPlusCircle } from "react-icons/fa"
import useOAuth from "../../lib/useOAuth"

export default function DrawingNewSingle() {
    const [ newDrawing, setNewDrawing ] = useState<NewSingleDrawing>(new NewSingleDrawing())
    const [ submitting, setSubmitting ] = useState(false)
    const { authKey } = useOAuth()
    let navigate = useNavigate()

    const SubmitNewForm = () => {
        setSubmitting(true)
        
        axios({
            method: "post",
            url: "/api/drawings/single",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            data: newDrawing
        })
            .then(res => {
                const { id } = res.data
                navigate(`/drawings/${id}/`)
            })
            .catch(err => {
                console.log(err)
                window.alert("Could not add the new drawing at this time. Please try again later!")
                setSubmitting(false)
            })
    }

    const containsAValue = (value:string) => {
        if(value)
        {
            return true
        }
        else
        {
            return false
        }
    }

    const drawingValidation = (drawing: NewSingleDrawing) => {
        let valid = true
    
        if (!drawing.LOCATION) valid = false
        if (!drawing.TYPE) valid = false
        if (!drawing.TITLEBLOCK) valid = false
        if (!drawing.EXTENT) valid = false
        if (!drawing.DATE) valid = false
    
        return valid
    }

    return (
        <div className="container-fluid w-50 mt-3">
                        
            <DrawingSizeField label="Drawing Size:" value={newDrawing.DRAWINGSIZE} onChange={(value:string) => setNewDrawing({ ...newDrawing, DRAWINGSIZE: value}) } />

            <TextField label="Location:" value={newDrawing.LOCATION} onChange={(value:string) => setNewDrawing({ ...newDrawing, LOCATION: value}) } isValid={ containsAValue(newDrawing.LOCATION) } />

            <DrawingTypeField label="Type:" value={newDrawing.TYPE} onChange={(value:string) => setNewDrawing({ ...newDrawing, TYPE: value}) } isValid={ containsAValue(newDrawing.TYPE) } />

            <TextField label="Title Block:" value={newDrawing.TITLEBLOCK} onChange={(value:string) => setNewDrawing({ ...newDrawing, TITLEBLOCK: value}) } isValid={ containsAValue(newDrawing.TITLEBLOCK) } />

            <TextField label="Extent:" value={newDrawing.EXTENT} onChange={(value:string) => setNewDrawing({ ...newDrawing, EXTENT: value}) } isValid={ containsAValue(newDrawing.EXTENT) } />

            <DateField label="Date:" value={newDrawing.DATE} onChange={(value:string) => setNewDrawing({ ...newDrawing, DATE: value }) } isValid={ containsAValue(newDrawing.DATE) } />
            
            <TextField label="Remarks:" value={newDrawing.REMARKS} onChange={(value:string) => setNewDrawing({ ...newDrawing, REMARKS: value}) } />

            <CheckboxField label="As Constructed:" value={newDrawing.ASCON} onChange={(value:any) => setNewDrawing({ ...newDrawing, ASCON: value}) } />

            <TextField label="Consultant DWG No:" value={newDrawing.CONSULTDWGNO} onChange={(value:string) => setNewDrawing({ ...newDrawing, CONSULTDWGNO: value}) } />

            <TextField label="MRC Job Number:" value={newDrawing.MRC_JOB_NO}  onChange={(value:string) => setNewDrawing({ ...newDrawing, MRC_JOB_NO: value}) }/>
        
            <div className="d-flex justify-content-center">
                <button onClick={SubmitNewForm} className="mt-3 mb-4 btn btn-council-green" disabled={submitting || !drawingValidation(newDrawing)} title="Create Drawing">
                    { submitting && ( 
                        <>
                            <div className="spinner-border spinner-border-sm text-white me-2" role="status" />
                            Submitting
                        </>
                    )}

                    { !submitting && ( 
                        <>
                            <FaPlusCircle className="me-1" />
                            Add Drawing
                        </>
                    )}
                </button>
            </div>

        </div>
    )
}
