import React from "react"
import { FaSearchPlus, FaRedoAlt } from "react-icons/fa"
import ConditionGroup from "./ConditionGroup"
import classes from "./search-advanced.module.scss"

interface ConditionCollectionProps {
    query: any;
    onChange: Function;
    onSearch: Function;
}

export default function ConditionCollection({ query, onChange, onSearch } : ConditionCollectionProps) {    
    const disableSubmit = (query.$or && query.$or.length === 0) || (query.$and && query.$and.length === 0 )
    
    const onClear = () => {
        if(window.confirm("This will clear all Search Criteria. Press ok to continue!"))
        {
            onChange({ "$and": [ { "DWG_NO": { "$regex": "", "$options": "i" } } ] })
        }
    }

    return (
        <>
            <div className={classes.conditionCollection}>
                <ConditionGroup 
                    query={query} 
                    onGroupChange={onChange} 
                    DisplayTree={false} 
                    First={false} 
                    Last={false} 
                    OnlyItem={false} /> 

                <div className={classes.mainBtnContainer}>
                    <button className="btn btn-council-green"
                        onClick={() => onSearch()} 
                        disabled={disableSubmit} 
                        title="Search">
                        <FaSearchPlus /> Search
                    </button>

                    <button className="btn btn-council-yellow" 
                        onClick={() => onClear()} 
                        title="Clear Filters">
                        <FaRedoAlt /> Clear
                    </button>
                </div>
            </div>
        </>
    )
}