import React, { useState, useEffect } from "react"
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom"
import axios from "axios"
import useOAuth from "./lib/useOAuth"

import Drawing from "./models/Drawing"
import User from "./models/User"

import SharedLayout from "./features/shared-layout"
import BasicSearch from "./features/search-basic"
import AdvancedSearch from "./features/search-advanced"
import DrawingsListAll from "./features/drawing-view-all"
import DrawingsCreateSingle from "./features/drawing-new-single"
import DrawingsCreateBulk from "./features/drawing-new-multi"
import DrawingsViewEdit from "./features/drawing-view-single"
import ExportDrawer from "./features/export-drawer"
import ExportList from "./features/export-list"
import Loading from "./features/loading-spinner"
import PageNotFound from "./features/page-not-found"

export default function App() {
    const [ listToExport, setListToExport ] = useState<Drawing[]>([])
    const [ exportDrawerOpen, setExportDrawerOpen ] = useState(false)
    const [ currentUser, setCurrentUser ] = useState<User>()

    const [ loading, setLoading ] = useState(true)
    const { authKey } = useOAuth()

    useEffect(() => {
        if(!authKey) return

        let source = axios.CancelToken.source()
        axios({
            method: "get",
            url: "/api/user",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            cancelToken: source.token
        })
            .then(res => {
                setCurrentUser(res.data)
                setLoading(false)
            })
            .catch(console.log)
        
        return source.cancel
    }, [authKey])

    if(loading || !currentUser) return <Loading />

    const AppenedExportList = (items:Drawing[]) => {
        let tempList : Drawing[] = [ ...listToExport ]
        let tempListIds = tempList.map(drawing => drawing._id)

        for(let i = 0; i < items.length; i++)
        {
            if(tempListIds.indexOf(items[i]._id) === -1)
            {
                tempList.push(items[i])
            }
        }
        
        setListToExport(tempList)
    }

    return (
        <Router>
            <SharedLayout isUserAdmin={ currentUser.isAdmin }>
                { currentUser.isAdmin && <ExportDrawer open={ exportDrawerOpen } close={ () => setExportDrawerOpen(false) } exportList={ listToExport } setListToExport={setListToExport} /> }
                <Routes>
                    <Route path="/drawings" element={<DrawingsListAll AppenedExportList={ AppenedExportList } OpenExportList={ () => setExportDrawerOpen(true) } isUserAdmin={ currentUser.isAdmin } />} />
                    <Route path="/drawings/:drawingId" element={<DrawingsViewEdit isUserAdmin={ currentUser.isAdmin } />} />
                    { currentUser.isAdmin && <Route path="/all-exports" element={<ExportList />} /> }
                    { currentUser.isAdmin && <Route path="/new/single" element={<DrawingsCreateSingle />} /> }
                    { currentUser.isAdmin && <Route path="/new/bulk" element={<DrawingsCreateBulk />} /> }
                    <Route path="/search/basic" element={<BasicSearch AppenedExportList={ AppenedExportList } OpenExportList={ () => setExportDrawerOpen(true) } isUserAdmin={ currentUser.isAdmin } />} />
                    <Route path="/search/advanced" element={<AdvancedSearch view="search" AppenedExportList={ AppenedExportList } OpenExportList={ () => setExportDrawerOpen(true) } isUserAdmin={ currentUser.isAdmin } />} />
                    <Route path="/search/advanced/results" element={<AdvancedSearch view="results" AppenedExportList={ AppenedExportList } OpenExportList={ () => setExportDrawerOpen(true) } isUserAdmin={ currentUser.isAdmin } />} />
                    <Route path="/" element={<Navigate to="/search/basic" />} />
                    <Route path="*" element={ <PageNotFound /> } />
                </Routes>
            </SharedLayout>
        </Router>
    )
}
