import React from "react"
import Logo from "./logoWhite.svg"
import classes from "./shared-layout.module.scss"
import { NavLink } from "react-router-dom"
import { FaSearch, FaDrawPolygon, FaRegImages, FaSearchPlus, FaRegImage, FaLayerGroup } from "react-icons/fa"

interface SharedLayoutProps {
    isUserAdmin: boolean
    children: React.ReactNode
}

export default function SharedLayout({ isUserAdmin, children }: SharedLayoutProps) {
    return (
        <>
            <div className="container-fluid p-0">

                <nav className={"navbar navbar-dark sticky-top flex-md-nowrap p-0 " + classes.bgDark + " " + classes.navbarHeight}>

                    <div className={classes.navbarLogo}>

                        <img src={Logo} className={classes.councilLogo} alt="logo" />

                    </div>

                    <div className={classes.navbarHeader}>

                        <p className="m-0">MRC Drawing Index</p>

                    </div>

                </nav>

                <div className="row m-0">

                    <Sidebar isUserAdmin={ isUserAdmin } />
            
                    <main role="main" className="col-lg p-0">

                        {children}

                    </main>
                </div>
            </div>
        </>
    )
}

function Link({ to, children }: any) {
    return (
        <li>
            <NavLink to={to} className={({ isActive }) => "nav-link " + classes.navLink + " " + (isActive ? classes.navLinkActive : "")}>
                {children}
            </NavLink>
        </li>
    )
}

interface SidebarProps {
    isUserAdmin: boolean
}

function Sidebar({ isUserAdmin } : SidebarProps) {
    return (
        <div className={"sidebar " + classes.sidebar}>
            <div className="d-flex align-items-end" >
                <ul className="nav flex-column">
                    <span className={"nav-link text-muted " + classes.navLink}>
                        Search
                    </span>

                    <div className="ms-3">

                        <Link to="/search/basic" >
                            <span className={classes.verticallyAlign}>
                                <FaSearch />
                                <span className="p-1">Basic</span>
                            </span>
                        </Link>

                        <Link to="/search/advanced" >
                            <span className={classes.verticallyAlign}>
                                <FaSearchPlus />
                                <span className="p-1">Advanced</span>
                            </span>
                        </Link>

                    </div>

                    <span className={"nav-link text-muted " + classes.navLink}>
                        Drawings
                    </span>

                    <div className="ms-3">
                        {
                            isUserAdmin && 
                            <>                   
                                <Link to="/new/single">
                                    <span className={classes.verticallyAlign}>
                                        <FaRegImage />
                                        <span className="p-1">New</span>
                                    </span>
                                </Link>

                                <Link to="/new/bulk">
                                    <span className={classes.verticallyAlign}>
                                        <FaRegImages />
                                        <span className="p-1">New (Bulk)</span>
                                    </span>
                                </Link>
                            </>
                        }

                        <Link to="/drawings">
                            <span className={classes.verticallyAlign}>
                                <FaDrawPolygon />
                                <span className="p-1">View All</span>
                            </span>
                        </Link>
                    </div>  
                    
                    {
                        isUserAdmin &&
                        <>
                            <span className={"nav-link text-muted " + classes.navLink}>
                                Drawing Exports
                            </span>         

                            <div className="ms-3">
                                <Link to="/all-exports">
                                    <span className={classes.verticallyAlign}>
                                        <FaLayerGroup />
                                        <span className="p-1">View Exports</span>
                                    </span>
                                </Link>
                            </div> 
                        </>
                    }

                </ul>
            </div>
        </div>
    )
}