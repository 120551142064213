import React, { useEffect, useState } from "react"
import axios from "axios"
import { format } from "date-fns"
import { FaCloudDownloadAlt } from "react-icons/fa"
import useOAuth from "../../lib/useOAuth"
import classes from "./export-list.module.scss"
import Loading from "../loading-spinner"

interface ExportItem {
    _id: string
    status: string
    filename: string
    createdAt: string
}

export default function ExportList(){
    const [ allExports, setAllExports ] = useState<ExportItem[]>([])
    const [ loading, setLoading ] = useState(true)
    const { authKey } = useOAuth()

    useEffect(() => {
        if(!authKey) return

        let source = axios.CancelToken.source()
        axios({
            method: "get",
            url: "/api/export",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            cancelToken: source.token
        })
            .then(res => {
                setAllExports(res.data)
                setLoading(false)
            })
            .catch(console.log)
        
        return source.cancel
    }, [authKey])

    if(loading) return <Loading />

    const downloadExport = (exportToDownload:ExportItem) => {
        axios({
            method: "get",
            url: `/api/export/download-token/${ exportToDownload._id }`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
        })
            .then(attachment_data => {            
                const { token } = attachment_data.data

                const link = document.createElement("a")
                document.body.appendChild(link)
                link.href = `/export/${token}`
                link.target = "_blank"
                link.setAttribute("type", "hidden")
                link.click()
            })
            .catch(console.log)
    }

    return (
        <div className="container-fluid mt-5">
            <table className={"table table-hover " + classes.table}>
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Export ID</th>
                        <th scope="col">Status</th>
                        <th scope="col">Created At</th>
                        <th className={classes["float-right"]}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allExports.map((exportItem) => {

                            return (
                                <tr key={exportItem._id}>
                                    <td className="align-middle">
                                        <span>{ exportItem._id }</span>
                                    </td>
                                    <td className="align-middle">
                                        <span>{ exportItem.status }</span>
                                    </td>
                                    <td className="align-middle">
                                        <span>{ format(Date.parse(exportItem.createdAt), "dd/MM/yyyy p") }</span>
                                    </td>

                                    { exportItem.filename ? 
                                        <td className={ classes["float-right"] + " align-middle" }>
                                            <button className="btn btn-dark-blue" onClick={ () => downloadExport(exportItem) }>
                                                <FaCloudDownloadAlt />
                                            </button> 
                                        </td>                                            
                                        :
                                        <td className="align-middle">
                                            <p> </p>
                                        </td>                                        
                                    }                                    
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}