import React, { useEffect, useState } from "react"
import Pagination from "react-js-pagination"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { BsChevronLeft, BsChevronRight, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs"
import useQuery from "../../lib/useQuery"

import DrawingList from "../drawing-list"
import Loading from "../loading-spinner"
import DisplayOptions from "../drawing-list-options"
import ResultCount from "../result-count"
import ExportButton from "../export-button"

import DrawingApiRes from "../../models/DrawingApiRes"
import classes from "./drawing-view-all.module.scss"
import useOAuth from "../../lib/useOAuth"
import Drawing from "../../models/Drawing"

function isNumber(n:string) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n) } 

interface DrawingViewAllProps {
    AppenedExportList: (items: Drawing[]) => void
    OpenExportList: () => void
    isUserAdmin: boolean
}

export default function DrawingViewAll({ AppenedExportList, OpenExportList, isUserAdmin } : DrawingViewAllProps) {
    let { page: pageIdDefault = "1", limit : limitDefault = "25", sorting : sortingDefault = "DWG_NO", order : orderingDefault = "asc" } = useQuery()

    pageIdDefault = isNumber(pageIdDefault) ? pageIdDefault : "1"    
    limitDefault = isNumber(limitDefault) ? limitDefault : "25"
    
    const [ apiData, setApiData ] = useState<DrawingApiRes>()
    const [ activePage, setActivePage ] = useState(parseInt(pageIdDefault))
    const [ loadingNextPage, setLoadingNextPage ] = useState(false)
    const [ sorting, setSorting ] = useState(sortingDefault)
    const [ ordering, setOrdering ] = useState(orderingDefault)
    const [ resultsPerPage, setResultsPerPage ] = useState(limitDefault)
    const { authKey } = useOAuth()
    const [ selectedList, setSelectedList ] = useState<Drawing[]>([])

    let navigate = useNavigate()

    useEffect(() => {
        if(!authKey) return

        let source = axios.CancelToken.source()
        axios({
            method: "get",
            url: `/api/drawings?page=${activePage}&limit=${resultsPerPage}&sorting=${sorting}&order=${ordering}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authKey
            },
            cancelToken: source.token
        })
            .then(res => {
                setApiData(res.data)
                setLoadingNextPage(false)
            })
            .catch(console.log)

        navigate(`/drawings?page=${activePage}&limit=${resultsPerPage}&sorting=${sorting}`)
        
        return source.cancel
    }, [activePage, navigate, sorting, resultsPerPage, authKey, ordering])
    
    if(!apiData) return <Loading />

    const ChangePageNumber = (newPageNumber:number) => {        
        setLoadingNextPage(true)
        setActivePage(newPageNumber)
        window.scrollTo(0, 0)
    }

    const Paginator = () => {
        return (
            <div className="paginationContainer">                
                { loadingNextPage && <div className="spinner-border text-primary me-2 ms-2" role="status" /> }
                
                <Pagination
                    activePage={ activePage }
                    itemsCountPerPage={ parseInt(resultsPerPage) }
                    totalItemsCount={ apiData.totalPages * parseInt(resultsPerPage) }
                    pageRangeDisplayed={ 5 }
                    onChange={ ChangePageNumber }
                    firstPageText={ <BsChevronDoubleLeft /> }
                    lastPageText={ <BsChevronDoubleRight /> }
                    prevPageText={ <BsChevronLeft /> }
                    nextPageText={ <BsChevronRight /> }
                    activeLinkClass="active"
                />
            </div>
        )
    }

    const AddDrawingsToExport = () => {        
        AppenedExportList(selectedList)
        setSelectedList([])
    }

    return (
        <>
            <div className={ classes.allDrawingsHeading }>
                <h5>All Drawings</h5>
            </div>

            <div className={ classes.flexApart }>
                <div>
                    { isUserAdmin && <ExportButton AddButton={ selectedList.length > 0 } AddDrawingsClick={ AddDrawingsToExport } ViewDrawingsClick={ OpenExportList } /> }
                </div>

                <div className={ classes.flexRight }>
                    <DisplayOptions
                        resultsPerPageValue={ resultsPerPage }
                        resultsPerPageOnChange={ setResultsPerPage }
                        SortingOptionsValue={ sorting }
                        SortingOptionsOnChange={ setSorting }
                        OrderingOptionsValue={ ordering }
                        OrderingOptionsOnChange={ setOrdering }
                    />
                    <Paginator />
                </div>
            </div>

            <DrawingList drawings={ apiData.data } selectedList={ selectedList } setSelectedList={ setSelectedList } showListSelection={isUserAdmin} />

            <div className={ classes.flexRight }>
                <ResultCount totalResults={ apiData.totalLength } />
                <Paginator />
            </div>
        </>
    )
}